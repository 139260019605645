<template>
  <section>
    <navi-bar>
      {{$t('front.common.withdrawalApply')}}
    </navi-bar>
    <div class="applylistbtn">
      <a :class="{'on' : type == 'apply'}" @click="type = 'apply'">{{$t('front.common.withdrawalApply')}}</a>
      <a :class="{'on' : type === 'list'}" @click="type = 'list'">{{$t('front.mypage.withdrawalList')}}</a>
    </div>
    <template v-if="type == 'apply'">
      <div class="acountapply">
        <a @click="moneyinfo=!moneyinfo"><img src="@/assets_mobile/img/icon_question.svg" /></a>
      </div>
      <div class="moneyread">
        <h3>{{$t('front.cash.moneyCheckList')}}</h3>
        <ul>
          <li><span>{{$t('front.cash.moneyCheckListN')}}</span></li>
          <li><span>{{$t('front.cash.moneyCheckListB')}}</span></li>
          <li><span>{{$t('front.cash.moneyCheckListD')}}</span></li>
        </ul>
      </div>
      <!--ul class="gamemoney">
        <li>{{$t('front.cash.haveGameCash')}}</li>
        <li><span class="gn">{{thousand(coinAmt ? coinAmt.toString(): '0')}}</span>{{$t('front.common.money')}}</li>
      </ul>
      <div class="cashchange">
        <a class="cashchangebtn" @click="moneyChange">{{$t('front.cash.cashChange')}}</a>
      </div-->
      <ul class="gamemoney">
        <li>{{$t('front.give.outCash')}}</li>
        <li><span class="or">{{thousand(userData.cashAmt)}}</span>{{$t('front.common.money')}}</li>
      </ul>
      <p class="outmoney">{{$t('front.board.withdrawMoney')}}</p>
      <div class="outmoneywrite"><input type="text" class="numb" pattern="[0-9.,]+" :placeholder="$t('front.give.moneyInput')" v-model="getCurrentChargeMoney"/><a>{{$t('front.give.allMoney')}}</a></div>
      <ul class="applimoneybtn">
        <li><a @click="setMoney(10000)">+1{{$t('front.cash.manwon')}}</a></li>
        <li><a @click="setMoney(30000)">+3{{$t('front.cash.manwon')}}</a></li>
        <li><a @click="setMoney(50000)">+5{{$t('front.cash.manwon')}}</a></li>
        <li><a @click="setMoney(100000)">+10{{$t('front.cash.manwon')}}</a></li>
        <li><a @click="setMoney(300000)">+30{{$t('front.cash.manwon')}}</a></li>
        <li><a @click="setMoney(500000)">+50{{$t('front.cash.manwon')}}</a></li>
        <li><a @click="setMoney(1000000)">+100{{$t('front.cash.manwon')}}</a></li>
        <li><a @click="setCurrentChargeMoney = ''">정정</a></li>
      </ul>
      <p class="outmoney b0">{{$t('front.cash.withdrawPassword')}}</p>
      <div class="outmoneywrite"><input type="password" class="numb w100w" pattern="[0-9.,]+" :placeholder="$t('front.cash.inputPassword')" v-model="cashOutPass"/></div>
      <div class="moneyinfowrap" v-if="moneyinfo">
        <div class="moneyinfoappli">
          <a class="close" @click="moneyinfo=!moneyinfo"><img src="@/assets_mobile/img/icon_delete.svg" /></a>
          <h3 class="applih">{{$t('front.cash.moneyOutInput')}}</h3>
          <p class="applip bb0 pb0">{{$t('front.cash.moneyCheckListP')}}</p>
        </div>

        <!--ul class="appliinput">
          <li>보유 캐시</li>
          <li class="or">{{thousand(userData.cashAmt)}}</li>
        </ul>
        <ul class="appliinput">
          <li>보유 게임머니</li>
          <li class="or">{{thousand(coinAmt ? coinAmt.toString(): '0')}}<a class="myinfochng">캐시전환</a></li>
        </ul>
        <ul class="appliinput">
          <li>출금금액 입력</li>
          <li><input type="text" class="numb" pattern="[0-9.,]+" placeholder="직접 입력시 숫자만 입력해 주세요." v-model="getCurrentChargeMoney"/></li>
        </ul>

        <ul class="moneybtnwrap">
          <li class="one"><a @click="setMoney(10000)">1만원</a></li>
          <li class="one"><a @click="setMoney(30000)">3만원</a></li>
          <li class="one"><a @click="setMoney(50000)">5만원</a></li>
          <li class="two"><a @click="setMoney(100000)">10만원</a></li>
        </ul>
        <ul class="moneybtnwrap">
          <li class="thr"><a @click="setMoney(300000)">30만원</a></li>
          <li class="four"><a @click="setMoney(500000)">50만원</a></li>
          <li class="fiv"><a @click="setMoney(1000000)">100만원</a></li>
          <li class="six"><a @click="setMoney(0)">정정</a></li>
        </ul>

        <ul class="appliinput">
          <li>출금 비밀번호</li>
          <li><input type="password" class="numb" placeholder="비밀번호를 입력하세요." v-model="cashOutPass" /></li>
        </ul-->
      </div>
      <!--div class="moneytab">
        <a :class="{'on' : type == 'apply'}" @click="type = 'apply'"><span>출금신청</span></a>
        <a :class="{'on' : type == 'list'}" @click="type = 'list'"><span>출금내역</span></a>
      </div-->
      <div class="btnwrap">
        <a class="ok" @click="onClickTM">{{$t('front.board.apply')}}</a>
      </div>

    </template>

    <template v-if="type === 'list'">
      <date-filter-mobile @search="onCashListSearch"
                          @update="onChangeDate"
      />

      <div class="gamebetwrap">
        <template v-if="cashList.length > 0">
          <div class="gamebetlist" v-for="item in cashList" v-bind:key="item.cashIdx">
            <a class="close"><img src="@/assets_mobile/img/icon_delete.svg" /></a>
            <!--input type="checkbox" v-model="item.checked"-->
            <ul class="gamebetdetail textleft">
              <li class="ft13">{{computedCashStatus(item.cashStatus).text}} {{dateFormat(item.updDate)}}</li>
              <li class="ft15 mt10">{{$t('front.board.withdrawMoney')}}</li>
            </ul>
            <ul class="gamebetdetail textright">
              <li class="mt30 ft15"><span class="bl fb">{{thousand(item.cashAmt)}}</span> 원</li>
              <!--li class="gr ft13 mt10">{{dateFormat(item.updDate)}}</li-->
            </ul>
            <!--a :class="computedCashStatus(item.cashStatus).class">{{computedCashStatus(item.cashStatus).text}}</a--><!-- class="done cancel" -->
          </div>
        </template>
        <template v-else>
          <div class="gamebetlist">
            {{$t('front.common.notFoundList')}}
          </div>
        </template>
      </div>
      <!--div class="btnwrap">
        <a class="all" @click="onAllCheck('cashList')">{{allChecked ? '전체해제': '전체선택'}}</a>
        <a class="selec" @click="onRemoveMsg">선택삭제</a>
      </div-->
      <pagination-mobile v-if="pageInfo"
                         :pageNum="pageInfo.page"
                         :pageSize="pageInfo.count_per_list"
                         :totalCount="pageInfo.tatal_list_count"
                         @goToPage="onCashListSearchMobile"
      />
    </template>

    <transition name="fade">
      <TMmodal v-show="TMmodal" :isOpen="TMmodal" :type="'header'" @close="onSubmit" @cancel="onCloseTM"/>
    </transition>
<!--    <div class="loginmodal" v-if="TMmodal && state !== 'ready'">-->
<!--      <div class="modal-content agreeart">-->
<!--        <p id="mainTM" class="modaltxt"> &lt;!&ndash;유저페이지에서&ndash;&gt;-->
<!--          <span v-if="state === 'ready'">게임 완전 종료 처리중입니다.<br> 잠시만(1분이내) 기다려주세요.</span>-->
<!--          <span v-if="state === 'complete'">환전을 위한 유효성 검사가 완료되었습니다.<br> 환전신청을 눌러주세요.</span>-->
<!--          <span v-if="state === 'fail'">통합머니로 전환 실패. 고객센터로 문의주세요</span>-->
<!--        </p>-->
<!--        <button class="yes" @click="onSubmit">환전 신청하기</button>-->
<!--        <button class="closeBtn" @click="onCloseTM">X</button>-->
<!--      </div>-->
<!--    </div>-->
  </section>
</template>

<script>

import NaviBar from '@/components/common/mobile/NaviBar'
import PaginationMobile from '@/components/ui/PaginationMobile'
import DateFilterMobile from '@/components/ui/DateFilterMobile'
import Exchange from '@/views/member/money/exchange'

export default {
  name: 'exchange',
  data () {
    return {
      moneyinfo: false
    }
  },
  components: {
    NaviBar,
    PaginationMobile,
    DateFilterMobile
  },
  mixins: [
    Exchange
  ]
}
</script>

<style scoped src="@/styles_mobile/betlist.css"></style>
<style scoped src="@/styles_mobile/money.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>
<style scoped>
.morebtn {margin-bottom: 10px !important;}
.loginmodal {position: fixed;z-index: 999;left: 0;top: 0;width: 100%;height: 100%;overflow-y: hidden;background-color: rgb(0,0,0);background-color: rgba(0,0,0,0.8);display: flex;justify-content: center;align-items: center;}
.modal-content {background: #222; color:#fff; padding: 80px; width: auto; display: flex; flex-direction: column; gap: 20px; align-items: center;position: relative;}
.modaltxt{margin-top:0;text-align: left;font-size:18px;line-height: 2;display: flex;flex-direction: column;}
br {display: none;}
.agreeart .yes {border: 1px solid #e5972d;color: #fff;background-image: linear-gradient(to bottom, #e5972d, #784f16);
  box-sizing: border-box;
  width: 60%;
  height: 40px;
  font-size: 18px;
  border-radius: 10px;
  cursor: pointer;
}
.closeBtn {
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
  background: none;
  border: 0;
  color: #fff;
  font-size: 25px;
}
@media (max-width: 700px) {
  .modal-content {padding:40px 20px;margin: 50% auto; max-width: 90%; box-sizing: border-box;}
  .loginclose {top:20px; right: 20px;}
  .modaltxt {font-size: 18px;white-space: nowrap;}
  br {display: block;}
}
</style>
